@import './shared/styles/_vuetify-overrides.scss';


































































































.cassie-text-field {
	&--small {
		.v-input__control .v-input__slot {
			min-height: 0 !important;
			padding: 0 8px !important;
			display: flex !important;
			align-items: center !important;
		}

		.v-input__control .v-input__slot .v-input__append-inner {
			margin-top: 4px !important;
		}

		.v-input__control .v-input__slot label {
			margin-top: -12px !important;
		}

		.v-input__control .v-input__slot label.v-label--active {
			margin: 0 0 0 5px !important;
		}

		.v-text-field__details {
			margin: 2px !important;
		}

		.v-label {
			top: 18px !important;
			font-size: 14px !important;
		}

		input {
			font-size: 85%;
		}

		.v-label--active {
			-webkit-transform: translateY(-25px) scale(0.65) !important;
			transform: translateY(-25px) scale(0.65) !important;
		}
	}
}
